<template>
    <div class="inner_pages">
        <div class="respective_content" id="habit_tracking_index">
            <div class="acton_header">
                <div class="left_section">
                    <h2>Habit Plans</h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="right_section">
                    <ul>
                        <li @click="sortHabitTracking = !sortHabitTracking" v-tooltip="`Sort Habits Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'habit-tracking')" @click="importHabitTracking = !importHabitTracking" v-tooltip="`Import a shared habit scenario`" position="bottom"><img src="@/assets/images/import.svg"></li>
                        <li class="add_btn light pointer" @click="newHabitTracking = true;"><i class="fas fa-plus-circle"></i>Create Habit Scenario</li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul class="left">
                        <li class="p-0" v-show="selectedHabitTracking.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedHabitTracking.length ? `${selectedHabitTracking.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="selectedHabitTracking.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('update', 'habit-tracking')" @click="handleAssignContacts(selectedHabitTracking)">Assign Contacts</li>
                                    <li v-if="companyUserCan('update', 'habit-tracking')" @click="handleUnassignContacts(selectedHabitTracking)">Unassign Contacts</li>
                                    <li v-if="companyUserCan('delete', 'habit-tracking')" @click="handleDeleteHabitTracking(selectedHabitTracking);" class="danger">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                            <label>Tutorials</label><img src="@/assets/images/play.svg">
                            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                <ul>
                                    <li @click="tutorial = true;">How to use the habits feature</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <ul class="right">
                        <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="habit-tracking-per-page-filter" />
                        </li>
                        <li class="filter_btn" @click="mobile_filter = true;">
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                    </ul>
                </div>
                <div class="listing_loader" v-if="habitTrackingLoader"><quote-loader /></div>
                <div class="action_content" v-if="!habitTrackingLoader">
                    <ul class="playbook_list">
                        <li v-for="(habitTracking, h) in habitTrackings.data" :key="h">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedHabitTracking.includes(habitTracking.id) }">
                                    <img v-if="habitTracking.dashboard_thumb" :src="habitTracking.dashboard_thumb" class="img">
                                    <img v-else src="@/assets/images/thumb/default-habit.svg" class="img">
                                    <span v-if="companyUserCan('update', 'habit-tracking')" class="replace_img" @click="editThumbnail = true; activeHabitTracking = habitTracking;">
                                        <img src="@/assets/images/image.svg">
                                    </span>
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`habit-tracking-${habitTracking.id}`" class="checkbox">
                                                <input type="checkbox" :id="`habit-tracking-${habitTracking.id}`" :value="habitTracking.id" v-model="selectedHabitTracking" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>{{ habitTracking.total_assigned ? habitTracking.total_assigned : 0 }} Assigned</li>
                                        <li>{{ habitTracking.total_submitted ? habitTracking.total_submitted : 0 }} Submitted</li>
                                        <li>{{ habitTracking.recurring_type_text ? habitTracking.recurring_type_text : '' }}</li>
                                    </ul>
                                </div>
                                <div v-tooltip="habitTracking.name">
                                    <a class="item_ttl pointer" v-if="companyUserCan('update', 'habit-tracking')" @click="openHabitTrackingWizard(habitTracking)">{{ habitTracking.name }}</a>
                                    <a class="item_ttl pointer" v-else>{{ habitTracking.name }}</a>
                                </div>
                                <div class="item_info">
                                    <div class="member pointer" @click="manageContact = true; activeHabitTracking = habitTracking;" v-if="!habitTracking.is_shared_with_contact" v-tooltip="`Clients Assigned to this Habit`">
                                        <img src="@/assets/images/member.svg">
                                        {{ habitTracking.total_assigned ? habitTracking.total_assigned : 0 }} Assigned
                                    </div>
                                    <ul>
                                        <li @click="habitTrackingLink = true; activeHabitTracking = habitTracking;"><span v-tooltip="`Live Habit Link`"><i class="fas fa-link"></i></span></li>
                                        <li @click="moreOption(habitTracking.id)">
                                            <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                            <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  habitTracking.id}">
                                                <li v-if="companyUserCan('update', 'habit-tracking')"><a @click="openHabitTrackingWizard(habitTracking)">Edit</a></li>
                                                <li @click="editThumbnail = true; activeHabitTracking = habitTracking;" v-if="companyUserCan('update', 'habit-tracking')">Edit Thumbnail Image</li>
                                                <li @click="renameHabitTracking = true; activeHabitTracking = habitTracking;" v-if="companyUserCan('update', 'habit-tracking')">Rename</li>
                                                <li @click="manageContact = true; activeHabitTracking = habitTracking;" v-if="!habitTracking.is_shared_with_contact">Manage</li>
                                                <li @click="previewHabitTracking = true; activeHabitTracking = habitTracking;">Preview</li>
                                                <li @click="handleShareHabitTracking(habitTracking)" >Share</li>
                                                <li @click="handleAssignContacts([habitTracking.id])" v-if="!habitTracking.is_shared_with_contact">Assign Contacts</li>
                                                <li @click="handleDuplicateHabitTracking([habitTracking.id]);" v-if="companyUserCan('create', 'habit-tracking')">Duplicate</li>
                                                <li @click="handleDeleteHabitTracking([habitTracking.id]);" v-if="companyUserCan('delete', 'habit-tracking')" class="danger" >Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty_box" v-if="!habitTrackings.total">
                        <img src="@/assets/images/empty_state.svg">
                        <h4>Looks like you don't have any Habits yet. Click the play button to get started.</h4>
                    </div>
                </div>
                <div class="list_footer">
                    <ul>
                        <li>{{ habitTrackings.from ? habitTrackings.from : 0 }} - {{ habitTrackings.to ? habitTrackings.to : 0 }} of {{ habitTrackings.total ? habitTrackings.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination">
                    <pagination v-model="page" :pages="habitTrackings.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="header_filter" :class="{'show' : mobile_filter}">
                <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                <div class="top_area">
                    <h3>Actions</h3>
                    <ul>
                        <li @click="sortHabitTracking = !sortHabitTracking" v-tooltip="`Sort Habits Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'habit-tracking')" @click="importHabitTracking = !importHabitTracking" v-tooltip="`Import a shared habit scenario`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    </ul>
                </div>
                <div class="action_area">
                    <h3 class="sub_heading2">Filters</h3>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <ul>
                        <li v-show="selectedHabitTracking.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedHabitTracking.length ? `${selectedHabitTracking.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2" v-click-outside="closeBulkActionDropdown2" v-if="selectedHabitTracking.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown2 ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('update', 'habit-tracking')" @click="handleAssignContacts(selectedHabitTracking)">Assign Contacts</li>
                                    <li v-if="companyUserCan('update', 'habit-tracking')" @click="handleUnassignContacts(selectedHabitTracking)">Unassign Contacts</li>
                                    <li v-if="companyUserCan('delete', 'habit-tracking')" @click="handleDeleteHabitTracking(selectedHabitTracking);" class="danger">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2"  v-click-outside="closeSortingDropdown2">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/1ee91264b13e4d68bf0f6ae6e6b900c3?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
        <edit-thumbnail-asset v-model="editThumbnail" title="Habit Tracking" :selected-asset="activeHabitTracking" :refreshAsset="refreshHabitTracking" />
        <import-share-link v-model="importHabitTracking" title="Habit Tracking" :refresh-asset="refreshHabitTracking" :has-saved-styling="true" />
        <sort-asset v-model="sortHabitTracking" title="Habit Tracking" asset-type="habit-tracking" :update-sorting="sortingFilter" />
        <rename-habit-tracking v-model="renameHabitTracking" :habit-tracking="activeHabitTracking" :refresh-habit-tracking="refreshHabitTracking" />
        <share-component v-model="shareHabitTracking" title="Habit Tracking" :selected-asset="activeHabitTracking" />
        <habit-wizard v-model="habitTrackingWizard" />
        <asset-link v-model="habitTrackingLink" title="Habit Scenario" :selected-asset="activeHabitTracking" />
        <assign-contact v-model="assignContact" :habit-tracking-ids="habitTrackingIds" :refresh-habit-tracking="refreshHabitTracking" />
        <unassign-contact v-model="unassignContact" :habit-tracking-ids="habitTrackingIds" :refresh-habit-tracking="refreshHabitTracking" />
        <manage-contact v-model="manageContact" :habit-tracking="activeHabitTracking" :refresh-habit-tracking="refreshHabitTracking" />
        <preview-habit-tracking v-model="previewHabitTracking" :habit-tracking="activeHabitTracking" />
        <new-habit-tracking v-model="newHabitTracking" :refresh-asset="refreshHabitTracking" />
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const InnerSidebar = defineAsyncComponent(() => import('@/components/InnerSidebar'))
    const EditThumbnailAsset = defineAsyncComponent(() => import('@/components/EditThumbnailAsset'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const RenameHabitTracking = defineAsyncComponent(() => import('@/pages/habit-tracking/components/RenameHabitTracking'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const HabitWizard = defineAsyncComponent(() => import('@/pages/habit-tracking/components/Wizard'))
    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const AssignContact = defineAsyncComponent(() => import('@/pages/habit-tracking/components/AssignContact'))
    const UnassignContact = defineAsyncComponent(() => import('@/pages/habit-tracking/components/UnassignContact'))
    const ManageContact = defineAsyncComponent(() => import('@/pages/habit-tracking/components/ManageContact'))
    const PreviewHabitTracking = defineAsyncComponent(() => import('@/pages/habit-tracking/components/PreviewHabitTracking'))
    const NewHabitTracking = defineAsyncComponent(() => import('@/pages/habit-tracking/components/NewHabitTracking'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: "Habit Tracking",

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                filterTitle: 'Custom Arrangement',
                filters: [
                    { title: 'Custom Arrangement', field: 'order', order: 'desc'},
                    { title: 'A-Z', field: 'name', order: 'asc'},
                    { title: 'Z-A', field: 'name', order: 'desc'},
                    { title: 'Date Created', field: 'id', order: 'desc'},
                    { title: 'Date Modified', field: 'updated_at', order: 'desc'},
                ],
                search: '',
                perPageFilter: 6,
                habitTrackingWizard: false,
                actionList: false,
                actionList2: false,
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                editThumbnail: false,
                activeHabitTracking: {},
                isTyping: false,
                isMoreOption: false,
                moreOptionDropdown: 0,
                selectedHabitTracking: [],
                importHabitTracking: false,
                sortHabitTracking: false,
                renameHabitTracking: false,
                shareHabitTracking: false,
                habitTrackingLink: false,
                assignContact: false,
                habitTrackingIds: [],
                unassignContact: false,
                manageContact: false,
                previewHabitTracking: false,
                page: 1,
                showContent: false,
                searchField: false,
                newHabitTracking: false,
                mobile_filter: false,
            }
        },

        components: {
            // InnerSidebar,
            EditThumbnailAsset,
            ImportShareLink,
            SortAsset,
            RenameHabitTracking,
            ShareComponent,
            HabitWizard,
            AssetLink,
            AssignContact,
            UnassignContact,
            ManageContact,
            PreviewHabitTracking,
            NewHabitTracking,
        },

        watch:{
            search (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'search', value: val });

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getHabitTrackings(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getHabitTrackings(vm.params);
                        }
                    }
                }
            },

            perPageFilter (perPage) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });
                vm.selectedHabitTracking = []

                vm.getHabitTrackings(vm.params);
            },

            habitTrackingWizard (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            previewHabitTracking (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            manageContact (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: {
            ...mapState({
                habitTrackings: state => state.habitTrackingModule.habitTrackings,
                habitTrackingLoader: state => state.habitTrackingModule.habitTrackingLoader,
                params: state => state.habitTrackingModule.params,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            // vm.resetDashboardParams();

            if (vm.habitTrackings.total == 0 || !vm.habitTrackings.total) {
                vm.getHabitTrackings(vm.params);
            }

            if (vm.params.search) {
                vm.search = vm.params.search;
            }

            if (vm.params.per_page) {
                vm.perPageFilter = vm.params.per_page;
            }

            if (vm.params.filter_title) {
                vm.filterTitle = vm.params.filter_title;
            }

            if (vm.params.page) {
                vm.page = vm.params.page;
            }

            document.getElementById('habit_tracking_index').onclick = function() {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }
        },

        methods: {
            ...mapActions({
                getHabitTrackings: 'habitTrackingModule/getHabitTrackings',
                duplicateHabitTracking: 'habitTrackingModule/duplicateHabitTracking',
                deleteHabitTracking: 'habitTrackingModule/deleteHabitTracking',
            }),

            ...mapMutations({
                setHabitTrackingCategories: 'habitTrackingModule/SET_HABIT_TRACKING_CATEGORIES',
                setSelectedHabitTracking: 'habitTrackingModule/SET_SELECTED_HABIT_TRACKING',
                setDashboardParams: 'habitTrackingModule/SET_DASHBOARD_PARAMS',
                resetDashboardParams: 'habitTrackingModule/RESET_DASHBOARD_PARAMS',
            }),

            togglePerPageFilter () {
                const vm = this;
                vm.actionList = false;
                vm.bulkActionDropdown = false;

                const filter = vm.$refs['habit-tracking-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['habit-tracking-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },
            closeBulkActionDropdown2() {
                const vm = this;

                vm.bulkActionDropdown2 = false;
            },
            
            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            moreOption (id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            handlePagination (page) {
                const vm = this;

                vm.page = page;
                vm.setDashboardParams({ key: 'page', value: page });
                vm.selectedHabitTracking = []

                vm.getHabitTrackings(vm.params);
            },

            sortingFilter (filter) {
                const vm = this;

                vm.filterTitle           = filter.title;
                vm.selectedHabitTracking = []

                vm.setDashboardParams({ key: 'filter_title', value: filter.title });
                vm.setDashboardParams({ key: 'order_by', value: filter.field });
                vm.setDashboardParams({ key: 'order', value: filter.order });
                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.getHabitTrackings(vm.params);
            },

            openHabitTrackingWizard (habitTracking) {
                const vm = this;

                vm.setHabitTrackingCategories(habitTracking.categories);
                vm.setSelectedHabitTracking(habitTracking);
                vm.habitTrackingWizard = true;
            },

            refreshHabitTracking () {
                const vm = this;

                vm.search                = '';
                vm.selectedHabitTracking = []

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.getHabitTrackings(vm.params);
            },

            handleShareHabitTracking (habitTracking) {
                const vm = this;

                if (habitTracking.share_count == 1) {
                    vm.shareHabitTracking = true;
                    vm.activeHabitTracking = habitTracking;
                } else if (habitTracking.share_count == 0) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);

                    Swal.fire(options);
                }
            },

            handleDuplicateHabitTracking (id) {
                const vm = this;

                if (id.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate ${ id.length > 1 ? 'these habit scenarios' : 'this habit scenario'}`);
                          options.preConfirm = function () {
                                                    return vm.duplicateHabitTracking({ id: id.toString() }).then((result) => {
                                                        vm.search                = '';
                                                        vm.selectedHabitTracking = [];

                                                        vm.setDashboardParams({ key: 'search', value: '' });
                                                        vm.getHabitTrackings(vm.params);
                                                    });
                                                };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one habit scenario to duplicate!');
                }
            },

            handleDeleteHabitTracking (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${ ids.length > 1 ? 'these habit scenarios' : 'this habit scenario'}`, 'Delete');
                          options.preConfirm = function () {
                                                    return vm.deleteHabitTracking({ ids }).then((result) => {
                                                        if (result) {
                                                            vm.refreshHabitTracking();
                                                        }
                                                    });
                                                };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one habit scenario to delete!');
                }
            },

            handleAssignContacts (ids) {
                const vm = this;

                if (ids.length) {
                    vm.habitTrackingIds = ids;
                    vm.assignContact    = true;
                } else {
                    Toastr.error('Please select at least one habit scenario to assign!');
                }
            },

            handleUnassignContacts (ids) {
                const vm = this;

                if (ids.length) {
                    vm.habitTrackingIds = ids;
                    vm.unassignContact  = true;
                } else {
                    Toastr.error('Please select at least one habit scenario to unassign!');
                }
            },

            showAction (ev) {
                const vm = this;

                if (ev) {
                    vm.showContent = true;
                } else {
                    vm.showContent = false;
                }
            },
        }
    }
</script>

<style scoped>
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    .playbook_list {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list > li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
        /* width: 33.333%; */
    }

    .plybook_item {
        border: 1px solid #E0E0E0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: 15px;
        top:15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .plybook_item .item_img .checkbox span{
        border-color: #8a8a8a;
    }
    .plybook_item .item_img .checkbox span i {
        color: #2c3e50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info > ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info > ul > li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics, .plybook_item .item_img.active .statistics {
        opacity: 1;
    }

    @keyframes dropMove2  {

        from  {margin-bottom: 10px; opacity: 0;}

        to  {margin-bottom: 1px; opacity: 1;}
    }


    .share_playbook .modal_container {
        height: 640px;
    }

    .modal.share_playbook .modal_header {
        padding: 20px 30px 0;
    }

    .modal.share_playbook .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr  {
        width: 100%;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .folder_list {
        display: flex;
        margin-top: 20px;
    }

    .folder_list li {
        width: 33.333%;
    }

    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0,0,0,0.13);
    }


    .result_wpr.small .actions > ul {
        height: 35px;
    }

    .result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    }

    .result_wpr.small .actions > ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td  {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table td .user_img, .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4  {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i {
        color: #2f7eed;
    }

    .folders_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .folders_list li {
        padding: 0 5px;
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 15px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
    }

    .folders_list .folder_item img {
        max-width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 44px rgba(0,0,0,0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }

    .folders_list .folder_item .dropdown {
        margin-left: 20px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }

    .modal.redirect_modal .modal_container {
        height: 420px;
    }

    .modal.redirect_modal .section_ttl {
        font-size: 16px;
        line-height: 20px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 0 10px;
    }

    .modal.redirect_modal .section_ttl span {
        padding: 0 10px;
        background: #fff;
    }

    .modal.redirect_modal .section_ttl:after {
        content: '';
        position: absolute;
        left: 7px;
        right: 7px;
        top: 50%;
        border-bottom: 1px solid #e9e9e9;
        z-index: -1;
    }

    .redirect_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .redirect_list li {
        width: 33.333%;
        padding: 5px 8px;
    }

    .redirect_list li label h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 10px;
        cursor: pointer;
        background: rgba(242, 163, 29, 0.05);
        border: 1px solid rgba(242, 163, 29, 0.3);
        color: #757575;
        filter: grayscale(1);
    }

    .redirect_list li label input[type=radio]:checked ~ h5 {
        filter: grayscale(0);
        color: #f2a31d;
    }

    .redirect_action {
        width: 100%;
        padding: 0 8px;
    }

    .modal.redirect_modal .action_wpr {
        margin-top: auto;
    }

    .ques_listing li {
        font-size: 14px;
        line-height: 19px;
        list-style-type:decimal;
        margin-left: 17px;
        padding: 10px 0 10px 5px;
        border-bottom: 1px solid #e9e9e9;
    }

    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width:25px;
        height: 25px;
        border-radius: 50%;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    /* @media(max-width: 1199px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 25px;
        }
        .acton_header .left_section .search_area, .acton_header .right_section > ul > li.add_btn{
            margin-left: auto;
        }
        .acton_header .search_area input, .acton_header .search_area .search_btn{
            height: 30px;
        }
    } */
    @media(max-width: 1199px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn){
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    /* @media(max-width: 600px){
        .acton_header {
            gap: 0;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section, .result_wpr.new .actions{
            margin-top: 15px;
        }
        .result_wpr.new .actions > ul li button.video_btn{
            margin-left: 10px;
        }
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: calc(100% + 14px);
        }
        .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
            margin-left: auto;
        }
        .tutorial_btn .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    } */
    @media(min-width: 1200px){
        .header_filter{
            display: none;
        }
    }
</style>
